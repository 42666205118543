<template>
  <collapse-card>
    <template #cardContent>
      <base-checkbox
        v-for="(filter, fIndex) in filterOptions"
        :key="'filter' + (filter.id || fIndex)"
        :model-value="selectedOptions"
        :value="filter.value"
        container-class="flex items-center mb-2 relative"
        class="focus:shadow-none opacity-0 absolute w-full z-10 cursor-pointer"
        @update:modelValue="handleSelectedOptions($event)"
      >
        <template #label>
          <label
            class="flex justify-between ml-1 w-full"
            :class="selectedOptions.includes(filter.value) ? 'text-primary-red font-semibold' : ''"
          >
            <span class="pr-1">
              {{ filter.value }}
            </span>
            <span>
              {{ filter.count }}
            </span>
          </label>
        </template>
      </base-checkbox>
      <base-button
        v-if="hasExpandedValues"
        class="focus:outline-none"
        @click="toggleExpanded()"
      >
        <template #default>
          <span
            v-if="!showingExpandedValues"
            class="text-sm font-bold text-primary-red"
          >
            + {{ filterData.options.length - filterOptions.length }} More
          </span>
          <span
            v-else
            class="text-sm font-bold text-primary-red"
          >
            Less
          </span>
        </template>
      </base-button>
    </template>
  </collapse-card>
</template>

<script>
import { computed, defineAsyncComponent, ref } from 'vue';

export default {
    name: 'AssetsFilterCard',

    components: {
        CollapseCard: defineAsyncComponent(() => import('@/components/CollapseCard.vue')),
        BaseButton: defineAsyncComponent(() => import('@/components/generic-components/BaseButton.vue')),
        BaseCheckbox: defineAsyncComponent(() => import('@/components/generic-components/BaseCheckbox.vue'))
    },

    props: {
        filterData: {
            type: Object,
            default: () => {},
            description: 'data of a single filter'
        },
        displayLimit: {
            type: Number,
            default: 3,
            description: 'no. of options to be displayed, when filters are collapsed(default is 3)'
        },
        selectedFilterOptions: {
            type: String,
            default: '',
            description: 'pre-selects options(multiple values should be separated |)'
        }
    },

    emits: [
        'optionSelected'
    ],

    setup (props, { emit }) {
        // see more options logic
        const showingExpandedValues = ref(false);
        const toggleExpanded = () => {
            showingExpandedValues.value = !showingExpandedValues.value;
        };

        const hasExpandedValues = computed(() => props.filterData.options.length > props.displayLimit);

        const filterOptions = computed(() => {
            let options = [];
            if (showingExpandedValues.value) options = props.filterData.options;
            else {
                if (hasExpandedValues.value) options = props.filterData.options.slice(0, props.displayLimit);
                else options = props.filterData.options;
            }

            return options.sort((a, b) => b.count - a.count);
        });

        // selecting options logic;
        const selectedOptions = ref([]);
        const emitOptionSelectedEvent = () => {
            emit('optionSelected', {
                queryParamKey: props.filterData.queryParamKey,
                selectedOptions: selectedOptions.value.join('|')
            });
        };
        const handleSelectedOptions = (newVal) => {
            selectedOptions.value = newVal;
            emitOptionSelectedEvent();
        };

        // const { searchParams } = useStyleGuideAssets();
        // watch(
        //     () => searchParams.value,
        //     () => {
        //         // clear selected options if all search filters are cleared
        //         if (searchParams.value && Object.keys(searchParams.value).length === 1) {
        //             selectedOptions.value = [];
        //         }
        //     }
        // );

        const clearAllSelections = () => {
            selectedOptions.value = [];
        };

        return {
            filterOptions,
            toggleExpanded,
            showingExpandedValues,
            hasExpandedValues,
            selectedOptions,
            handleSelectedOptions,
            clearAllSelections
        };
    }
};
</script>
